html {
  border-top: 0.3em solid var(--color-red);
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: border-top var(--speed-quick) ease, background var(--speed-quick) ease,
    color var(--speed-quick) ease;
}

[hidden] {
  display: none !important;
}

a {
  transition: color var(--speed-quick) ease;

  &:link {
    color: var(--color-blue);
    transition: color 0.3s ease;

    text-decoration-skip: ink;
  }

  &:visited {
    color: var(--color-violet);
  }

  &:link:hover,
  &:visited:hover {
    color: var(--color-red);
  }
}

small,
.text-small {
  display: inline-block;
  color: var(--app-text-color);
  font-weight: normal;
  font-size: 0.85rem;
  line-height: 1.4;
  opacity: 0.35;
  // transition: color var(--speed-quick) ease;
}

.visuallyHidden {
  position: absolute !important;
  bottom: 100%;
  opacity: 0;
  opacity: 0.001;
  pointer-events: none;
}
